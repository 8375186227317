/* @import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

:root{
  --color-gradient: linear-gradient(88.95deg, #2C87FF -37.4%, #29EAC4 127.47%);
}

*{
  font-family: 'DM Sans' !important;
}
.color-gradient{
  background: -webkit-linear-gradient(88.95deg, #2C87FF -37.4%, #29EAC4 127.47%) !important;
  /* background-color: var(--color-gradient) !important; */
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}
.pageTitle {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 110%;
    text-transform: capitalize;
    color: #1976d2;
    margin: 0;
  }
.pageSubTitle {
  font-family: 'DM Sans' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 150% !important;
  text-transform: capitalize !important;
  color: #1C75BC !important;
  margin: 0;
}

.titleText{
  font-family: 'DM Sans';
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
}

.overflow-text-1{
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.titleText:hover {
  color: #1D5DAB !important;
  text-decoration: underline !important;
}

.error-message{
  font-size: 12px;
  color: red;
  margin: 0;
  height: 18px;
}
.supportedFileTypes {
  color: rgba(0, 0, 0, 0.6);
  font-family: 'DM Sans';
  font-weight: 400;
  font-size: 0.7rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  margin-bottom: 5px;
}
.MuiTablePagination-selectLabel{
  margin-bottom: 0 !important;
}
/* .inputLabel {
  color: #1F2937 !important;
  font-weight : 400 !important;
} */
.MuiInputLabel-root{
  color: #1F2937 !important;
  font-weight : 400 !important;
  margin-bottom: 5px;
}
.MuiInputBase-root{
  border-radius: 8px !important;
}
.MuiOutlinedInput-notchedOutline{
  border-color: rgba(0, 0, 0, 0.10) !important;
}
.placeholder_fix input[value=""]:before {
  content: attr(data-placeholder);
  color: #9d9d9d;
  position: absolute;
  background: #fff;
  top: 0;
  bottom: 0;
  left: 0;
  right: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}