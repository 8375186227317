.base {
    padding: 8px 16px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    border: none;
    border-radius: 8px;
}
.base:disabled {
    opacity: 0.3;
    cursor: not-allowed;
}

.contained {
    color: #FFFFFF;
    background: linear-gradient(88.95deg, #2C87FF -37.4%, #29EAC4 127.47%);
}

.outline {
    border: none;
    cursor: pointer;
    outline: none;
    transform: translate(0);
    background-image: linear-gradient(88.95deg, #2C87FF -37.4%, #29EAC4 127.47%);
    box-shadow: 0 22px 44px rgba(128, 128, 128, 0.1);
    transition: box-shadow 0.25s;
  }
.outline .text {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(88.95deg, #2C87FF -37.4%, #29EAC4 127.47%);
}
.outline:after {
    content: "";
    border-radius: 8px;
    position: absolute;
    margin: 1px;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    background: white;
}

.outlineHoverOnly:hover {
    border: none;
    border-radius: 8px;
    cursor: pointer;
    outline: none;
    transform: translate(0);
    background-image: linear-gradient(88.95deg, #2C87FF -37.4%, #29EAC4 127.47%);
    box-shadow: 0 22px 44px rgba(128, 128, 128, 0.1);
    transition: box-shadow 0.25s;
  }
.outlineHoverOnly:hover .outlineTextHoverOnly {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(88.95deg, #2C87FF -37.4%, #29EAC4 127.47%);
}
.outlineHoverOnly:hover:after {
    content: "";
    border-radius: 8px;
    position: absolute;
    margin: 1px;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    background: white;
}