.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    padding: 10px;
    color: #A9A9A9;
}
.closeButton:hover {
    fill: #144475;
    color: #144475;
}