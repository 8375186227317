@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.carousel__title{
  color: #3683EF;
  font-size: 2.5rem;
  font-weight: 700;
}

.carousel__caption{
  font-size: 1.125rem;
  color: #1F293799;
  margin: 0;
}

.carousel.slide{
  height: 100% !important;
}

.carousel-control-next,
.carousel-control-prev{
  top: auto !important;
  right: auto !important;
  bottom: 80px !important;
  width: auto !important;
}

.carousel-control-prev{
  left: 85px !important;
}

.carousel-control-next{
  left: 165px !important;
}